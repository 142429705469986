import React from "react";

const Terms = () => {
  return (
    <div className="flex items-center justify-center min-h-screen py-10 bg-gray-50">
      <div className="w-full max-w-4xl mx-auto px-4 md:px-8">
        <h1 className="text-2xl md:text-3xl font-bold mb-6 text-pink-600 text-center">
          Terms & Conditions
        </h1>
        <h2 className="text-xl md:text-4xl font-bold mb-6 text-center">
          We do not buy from the open market & traders.
        </h2>
        <p className="text-sm md:text-base text-gray-700 leading-relaxed">
          The www.budandtulips.com collects your general information for a number of reasons; the foremost reason is to improve its customers' shopping experience and to communicate better based on your interests in the form of promotions, offers, and services. We will collect personal information like your name, age, phone number, and email that you provide when you save your details on our website. We will also collect financial information and postal address, which is optional for you to save with us.
        </p>
        <p className="text-sm md:text-base text-gray-700 leading-relaxed mt-4">
          In addition, we will also save your past purchases, product favorites, and whatever else seems important to enhance your shopping experience on our website. We also keep track of the use of our website to monitor traffic and improve the website. The www.budandtulips.com is bound to disclose some of the personal information when required by law, court, or government. We also might have to disclose personal information to our corporate family and the contracted company that helps in preventing the unauthorized use of cards.
        </p>
      </div>
    </div>
  );
};

export default Terms;
