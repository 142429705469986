import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen px-4 py-6 sm:px-6 sm:py-8 lg:px-8 lg:py-12">
      <div className="w-full max-w-md text-center sm:max-w-lg sm:text-left">
        <h1 className="text-xl font-bold mb-4 text-gray-800 sm:text-2xl">
          Privacy Policy
        </h1>
        <p className="text-sm text-gray-700 mb-4 sm:text-base sm:leading-relaxed">
          Bud & Tulip collects your general information for a number of reasons;
          the foremost reason is to improve its customers' shopping experience
          and to communicate better based on your interests in the form of
          promotions, offers, and services. We will collect personal
          information like your name, age, phone number, and email that you provide
          when you save your details on our website. We will also collect
          financial information and postal address, which is optional for you to
          save with us.
          <br /><br />
          In addition, we will also save your past purchases, product favorites, and
          whatever else seems important to enhance your shopping experience on our
          website. We also monitor the use of our website to track traffic and
          improve the site.
          <br /><br />
          Bud & Tulip is bound to disclose some of the personal information when
          required by law, court, or government. We might also have to disclose
          personal information to our corporate family and contracted companies who
          help in preventing unauthorized use of cards.
          <br /><br />
          <strong>All disputes</strong> are subject to <strong>Sirsa Jurisdiction</strong> only.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
