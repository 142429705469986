// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    font-family: 'Jost', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.1rem;
    padding-top: 120px !important;
}

/* For smaller screens */
@media (max-width: 767px) {
    body {
        padding-top: 60px !important;

        font-family: 'Jost', sans-serif !important;

    }
}

html, body {
    scroll-behavior: smooth;
    height: auto;
  }
  

@tailwind base;
@tailwind components;
@tailwind utilities;

.navbar-brand {
    font-family: 'Jost', sans-serif;
    font-weight: 500;
    /* font-style: normal; */
    /* font-size: rem; */
    /* padding-top: 110px !important; */
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,6BAA6B;AACjC;;AAEA,wBAAwB;AACxB;IACI;QACI,4BAA4B;;QAE5B,0CAA0C;;IAE9C;AACJ;;AAEA;IACI,uBAAuB;IACvB,YAAY;EACd;;;AAGF,cAAc;AACd,oBAAoB;AACpB,mBAAmB;;AAEnB;IACI,+BAA+B;IAC/B,gBAAgB;IAChB,wBAAwB;IACxB,oBAAoB;IACpB,mCAAmC;AACvC","sourcesContent":["body {\n    font-family: 'Jost', sans-serif;\n    font-weight: 400;\n    font-style: normal;\n    font-size: 1.1rem;\n    padding-top: 120px !important;\n}\n\n/* For smaller screens */\n@media (max-width: 767px) {\n    body {\n        padding-top: 60px !important;\n\n        font-family: 'Jost', sans-serif !important;\n\n    }\n}\n\nhtml, body {\n    scroll-behavior: smooth;\n    height: auto;\n  }\n  \n\n@tailwind base;\n@tailwind components;\n@tailwind utilities;\n\n.navbar-brand {\n    font-family: 'Jost', sans-serif;\n    font-weight: 500;\n    /* font-style: normal; */\n    /* font-size: rem; */\n    /* padding-top: 110px !important; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
